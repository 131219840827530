<template>
  <div>
    <div class="content">
      <div class="inner">
        <h1>Eli Ashby</h1>
        <h3><em>-- I make problems go away with code --</em></h3>
        <p>
          Whether it's a website, a Web Application, a Restful API or something a little more simple like making sense of that massively complicated spread sheet, or an automation script to make your life easier.
        </p>
        <p>
          I do it with <strong>Vue.js</strong>, <strong>Node.js</strong> and <strong>Python</strong>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home'
};
</script>
