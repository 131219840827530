<template>
  <div>
    <article id="about">
      <h2 class="major">About</h2>
      <div class="profile-pic">
        <img src="https://eliashby.com/images/profile-pic.jpg" />
      </div>
      <p>For as long as I can remember I liked to build and modify things. Whether it was houses, tying flies, cars or longbows and arrows. I had an addiction to creating things with my own two hands. Today that still exists, but with technology.</p>

      <p>I enjoy nothing more than seeing a line of code turn into something useful and beautiful. Whether it's a website to build a business or a simple python automation script to make my life a whole lot easier. I get a great amount of satisfaction out of it. It's always great to see something and be able to say "I built that".</p>

      <p>I have had the opportunity to build many great and diverse things from hundreds of websites, to automation applications to save days of work for me or other employees, to restful API's and raspberry pi projects.</p>

      <p>With creating and building things, also comes a high level of problem solving. If I were to pick one thing that defines my skill set, it's problem solving. I have experienced many problems in many jobs and I love nothing more than a good problem to solve, it keeps me interested and humble.</p>

      <p>Having said all that, what problems could I solve for you? Need a web application? An e-commerce website? Need that horrible spreadsheet to disappear? Let me know, and I will be happy to build you something and make that problem go away.</p>

      <p><router-link active-class="nav-active" to="/contact">Contact me</router-link> and let's work together to fix your problems.</p>
    </article>
  </div>
</template>

<script>
export default {
  name: 'about'
};
</script>

<style>
.profile-pic {
  max-width: 130px;
  border-radius: 100%;
  overflow: hidden;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
}

.profile-pic img {
  max-width: 100%;
}
</style>
