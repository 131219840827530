<template>
  <div>
    <article id="contact">
      <h2 class="major">Contact</h2>
      <!--
      <form method="post" action="#">
        <div class="fields">
          <div class="field half">
            <label for="name">Name</label>
            <input type="text" name="name" id="name" />
          </div>
          <div class="field half">
            <label for="email">Email</label>
            <input type="text" name="email" id="email" />
          </div>
          <div class="field">
            <label for="message">Message</label>
            <textarea name="message" id="message" rows="4"></textarea>
          </div>
        </div>
        <ul class="actions">
          <li>
            <input type="submit" value="Send Message" class="primary" />
          </li>
          <li>
            <input type="reset" value="Reset" />
          </li>
        </ul>
      </form>
      -->
      <p>
        Need help making a problem go away? Please get in contact with me and let me fix that for you. I look forward to it.
      </p>
      <p>
        <a href="mailto:eli@eliashby.com">eli@eliashby.com</a>
      </p>
      <ul class="icons">
        <li>
          <a href="https://www.linkedin.com/in/eliashby/" class="icon fa-linkedin">
            <span class="label">LinkedIn</span>
          </a>
        </li>
        <li>
          <a href="https://github.com/eliashby" class="icon fa-github">
            <span class="label">GitHub</span>
          </a>
        </li>
      </ul>
    </article>
  </div>
</template>

<script>
export default {
  name: 'contact'
}
</script>
