<template>
  <div>
    <article id="work">
      <h2 class="major">Work</h2>
      <!--
      <span class="image main">
        <img src="../assets/images/pic02.jpg" alt />
      </span>
      -->
      <h3>Salesforce Journey Activity</h3>
      <p>
        Created a Salesforce Marketing Cloud Journey Activity for SMS campaign automation using a third party SMS provider. The activity included a backend dashboard solution for tracking messages sent and easily seeing message success rates. Built with:
      </p>
      <ul>
        <li>Node.js</li>
        <li>jQuery</li>
        <li>Postmonger.js</li>
        <li>MongoDB</li>
        <li>Bootstrap 4</li>
      </ul>
      <hr>
      <h3>Event Brite API Automation</h3>
      <p>
        Built an automation application to take events ( ~50 per week ) from our Microsoft Dynamics CRM and build and create them in the Event Brite ticket selling platform, previously taking an employee 3 days per week previously to do it manually. Also built a webhook to take registrants from Event Brite and create them in our CRM, where previously this was being done manually as well with CSV exports. Included a backend dashboard to keep track of registrants and events currently deployed. Turned to being 100% automated by running bash scripts from CRON jobs on specific days to upload or update any events. Built with:
      </p>
      <ul>
        <li>Python 3</li>
        <li>Python Flask</li>
        <li>SQLite</li>
        <li>CRON</li>
        <li>Bash</li>
        <li>Redis</li>
        <li>RQ Worker</li>
        <li>Bootstrap 4</li>
      </ul>
      <hr>
      <h3>Vue.js Marketing funnel testing platform</h3>
      <p>
        Engineered and built a platform in order to quickly build and swap components to test in marketing funnels. Ran by a JSON settings file, marketers were able to create the file, link it on the page and be ready to go with a new test. They could choose which application goes in which step, which products to show where etc. Accomplished this by using vue.js and single file components that could be easily maintained by a developer as well as be able to use single components in a bigger view. Able to allow marketers to test quick and often without the need of a developer and resulted in a 10-20% increase in conversion rates.
      </p>
      <ul>
        <li>Vue.js</li>
        <li>Vue CLI</li>
        <li>Vuex</li>
        <li>Vue Router</li>
        <li>Webpack</li>
        <li>Bootstrap 4</li>
        <li>JSON</li>
        <li>AWS S3</li>
      </ul>
    </article>
  </div>
</template>

<script>
export default {
  name: 'work'
}
</script>
