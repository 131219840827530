<template>
  <div id="app">
    <div id="wrapper">
      <header id="header">
        <nav>
          <ul>
            <li>
              <router-link active-class="nav-active" to="/">Intro</router-link>
            </li>
            <li>
              <router-link active-class="nav-active" to="/about">About</router-link>
            </li>
            <li>
              <router-link active-class="nav-active" to="/work">Work</router-link>
            </li>
            <li>
              <router-link active-class="nav-active" to="/contact">Contact</router-link>
            </li>
          </ul>
        </nav>
      </header>
        <router-view class="main"></router-view>
      <footer id="footer">
        <p class="copyright">&copy; 2019 ELIASHBY.COM. ALL RIGHTS RESERVED.</p>
      </footer>
    </div>
    <div id="bg"></div>
  </div>
</template>

<script>
export default {
  name: 'app'
}
</script>

<style>
  @import "./assets/css/main.css";
</style>
